






















































import Vue from "vue";
import Project from "@/components/Project.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

/* Projects Display */
const projects = require("@/config/config.json").projects
/* const projectsKeys = Object.keys(projects)

let open = ""
let dev = ""
let closed = ""

let i = 0
for(i = 0; i < projectsKeys.length; i++) {
    const target = projects[projectsKeys[i]]
    let temp = ""

    temp = `<Project title=${projectsKeys[i]} />`
    console.log(temp)

    open += temp
} */

/* Page Export */
export default Vue.extend({
  components: { Project, Breadcrumb },

  data: function() {
    return {
      /* open: open,
      dev: dev,
      closed: closed */
      projects: projects
    }
  }
});
