














































import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  name: "Project",
  props: {
    title: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    site: {
      type: String,
      default: ""
    },
    twitter: {
      type: String,
      default: ""
    },
    discord: {
      type: String,
      default: ""
    },
    ip: {
      type: String
    }
  },
  created: async function() {
    if(this.ip) {
      await axios.get("https://api.mcsrvstat.us/2/" + this.ip).then(response => {
        let status = ""

        if(response.data.online === true) {
          status = 'Statut du serveur: <span class="badge badge-success">En ligne</span><br />'
          status = status += 'Joueurs connectés: <span class="badge badge-secondary">' + response.data.players.online + '/' + response.data.players.max + "</span>"
        } else {
          status = 'Statut du serveur: <span class="badge badge-danger">Hors ligne</span><br />'
        }

        document.getElementById(this.ip)!.innerHTML = status
      })
    }
  }
});
